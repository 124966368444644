@import '../common/color';

@media only screen and (max-width: 600px) {
  .ant-page-header-heading-title {
    .ant-row {
      flex-flow: row!important;
    }
    .ant-typography {
      font-size: 12px!important;
    }
  }
}

.header-fixed {
  height: initial!important;
  position: fixed!important;
  z-index: 10;
  width: 100%;
  padding: 16px!important;
  background: @white!important;
  
  .ant-page-header-heading {
    height: 100px;

    .ant-page-header-heading-title {
      width: 100vw;
      .logo_avatar {
        width: 70px;    
        height: 70px;
        margin: 0 auto;
        justify-self: center;
        align-self: center;
        background: url('/avatar.png') center center no-repeat;
        background-size: contain;
      }
    }
  }

  .ant-page-header-content {
    padding-top: 0px; 
  }

  .progress-level {
    position: fixed;
    left: 0px;
    margin-top: 5px;

    .ant-progress-inner {
      background: rgba(51, 126, 255, 0.3);
    }
    .ant-progress-success-bg, .ant-progress-bg {
      border-radius: 0px;
      background: @info-600;
    }
  }

}

.navbar {
  width: 100%;
  display: block;
  background: @black-100;
  position: fixed;
  padding: 10px 20px;
  z-index: 10;
}