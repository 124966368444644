@import '../common/color';

.domain-checkbox__group {
  .ant-checkbox-wrapper {
    padding: 5px 10px 5px 10px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}