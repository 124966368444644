@import '../common/color';

.domain-icon__button {
  border: 1px solid @black-200;
  background: @white 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 0px @shadow;
  border: 1px solid @black-200;
  border-radius: 12px;
  opacity: 1;
  width: 60px;
  height: 60px;
  padding: 0px;
}